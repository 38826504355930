window.$ = window.jQuery = require('jquery');
import * as Popper from '@popperjs/core'
window.Popper = Popper

require('./bootstrap');
require('moment');
window.moment = require('moment/moment');
require('bootstrap4-toggle');
require('bootstrap-3-typeahead');
//prismjs
require('prismjs');
const getLoader = require('prismjs/dependencies');
const components = require('prismjs/components');
const componentsToLoad = ['markup', 'css', 'php', 'python', 'bash'];
const loadedComponents = ['clike', 'javascript'];
const loader = getLoader(components, componentsToLoad, loadedComponents);
loader.load(id => {
    require(`prismjs/components/prism-${id}.min.js`);
});
//custom
require('./keyboard-pagination');
require('./helpers');
// window.bytesToSize = function bytesToSize(bytes) {
//     var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
//     if (bytes == 0) return '0 Byte';
//     var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
//     return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
// };

keyboardPagination('.pagination', {
    num: '.page-item',
    numCurrent: '.active'
});
$(window).on('shown.bs.modal', function () {
    keyboardPagination(false);
});
$(window).on('hidden.bs.modal', function () {
    keyboardPagination('.pagination', {
        num: '.page-item',
        numCurrent: '.active'
    });
});